import 'STYLES/app.scss';

import React, {useEffect, useState} from 'react';
import type {AppProps} from 'next/app';
import {Provider} from 'react-redux';
import store from 'STORE/index';
// import {getResizeDispatcher} from 'COMPONENTS/ui/resize/ResizeDispatcher';
// import {useOutliner} from 'COMPONENTS/ui/Outliner/Outliner';

function MyApp({Component, pageProps}: AppProps) {
  const [showChild, setShowChild] = useState(false);
  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    return null;
  }

  if (typeof window === 'undefined') {
    return <></>;
  } else {
    return (
      <Provider store={store}>
        <div className={'page-wrapper'}>
          <Component {...pageProps}/>
        </div>
      </Provider>
    );
  }
}

export default MyApp;
