import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {OptionsInterface} from 'TYPES/State';
import {Notice} from 'TYPES/types';

export const defaultLang = 'en';

const initialState: OptionsInterface = {
  lang: defaultLang,
  notices: [],
  popupOpened: false,
  activeRadio: '',
};

const optionsSlice = createSlice({
  name: 'options-reducer',
  reducers: {
    addNotice: (state: OptionsInterface, action: PayloadAction<Notice>) => {
      state.notices.push({
        ...action.payload,
        id: `notice-${Math.random()}`
      });
    },
    removeNotice: (state: OptionsInterface, action: PayloadAction<string>) => {
      state.notices = state.notices.filter((notice) => notice.id !== action.payload);
    },
    setLang: (state: OptionsInterface, action: PayloadAction<'en'>) => {
      state.lang = action.payload;
    },
    togglePopup: (state: OptionsInterface, action: PayloadAction<boolean>) => {
      state.popupOpened = action.payload;
    },
    setActiveRadio: (state: OptionsInterface, action: PayloadAction<string>) => {
      state.activeRadio = action.payload;
    }
  },
  // async reducers
  // extraReducers: (builder) => {
  //
  // },
  initialState: initialState
});

export const {addNotice, removeNotice, setLang, setActiveRadio, togglePopup} = optionsSlice.actions;
export default optionsSlice;
